.lander-container {
    min-height: 100vh;
    background-color: #0f0f23;
    color: #fff;
    padding: 2rem;
    padding-top: 80px;
    position: relative;
    z-index: 1;
  }
  
  .lander-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    position: relative;
    z-index: 2;
  }
  
  .lander-text {
    flex: 1;
    position: relative;
    z-index: 2;
  }
  
  .heading-dot {
    color: #dc004e;
  }
  
  .lander-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  
  .lander-image img {
    max-width: 100%;
    height: auto;
  }
  
  /* New morph animation for blob effects */
  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
    25% {
      border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }
    50% {
      border-radius: 50% 60% 30% 70% / 40% 60% 70% 40%;
    }
    75% {
      border-radius: 60% 40% 70% 30% / 60% 30% 70% 40%;
    }
    100% {
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
  }
  
  /* Shimmer effect for gradient text */
  @keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  @media (max-width: 900px) {
    .lander-content {
      flex-direction: column;
      text-align: center;
    }
  
    .feature-list {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }