/* Custom styles for marker clusters */
.marker-cluster-custom {
  background: transparent !important;
  border: none !important;
}

/* Target our custom inner div */
.custom-cluster-inner {
  border: none !important;
  z-index: 999 !important;
}

/* Override default marker cluster styles */
.leaflet-marker-icon.leaflet-div-icon.marker-cluster-custom {
  background: transparent !important;
  border: none !important;
}

/* Make sure our custom div inside the cluster is visible */
.marker-cluster-custom div {
  background-clip: padding-box;
  z-index: 999 !important;
}

/* Remove any default backgrounds */
.marker-cluster, 
.marker-cluster-small, 
.marker-cluster-medium, 
.marker-cluster-large {
  background-color: transparent !important;
}

.marker-cluster div, 
.marker-cluster-small div, 
.marker-cluster-medium div, 
.marker-cluster-large div {
  background-color: inherit !important;
}

/* Ensure our styles take precedence */
.leaflet-marker-pane .marker-cluster-custom {
  background: transparent !important;
} 